import Alpine from 'alpinejs'

Alpine.store(
	'navigation',
	{
		get isOpen() {
			return this._isOpen
		},

		get activeItem() {
			return this._activeItem
		},

		set activeItem(val) {
			this._activeItem = val
		},

		init() {
			this._isOpen = false
			this._activeItem = document.querySelector(`nav[x-data="navbar"] [x-bind="menu"] ul[data-primary] a[href="${ location.pathname }"]`)
		},

		close() {
			this._isOpen = false
		},

		toggle() {
			this._isOpen = !this._isOpen
		},
	},
)
