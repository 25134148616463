import barba from '@barba/core'
import Alpine from 'alpinejs'
import { animate } from 'motion'

const {
	beforeEnter,
	beforeLeave,
} = barba.hooks

beforeLeave(
	({ trigger }) => {
		const menu = document.querySelector('nav[x-data="navbar"] [x-bind="menu"] ul[data-primary]')

		Alpine.store('navigation').activeItem = menu.contains(trigger)
			? trigger
			: null
	},
)

beforeEnter(
	() => {
		Alpine.store('navigation').close()

		scrollTo({
			top: 0,
			behavior: 'instant',
		})
	},
)

barba.init({
	schema: {
		container: 'page-content',
		wrapper: 'page-wrapper',
		prefix: 'data-transition',
	},

	transitions: [
		{
			async leave({ current }) {
				await animate(
					[
						document.querySelector('footer'),
						current.container,
					],
					{
						opacity: 0,
					},
				).finished
			},

			async enter({ next }) {
				animate(
					[
						document.querySelector('footer'),
						next.container,
					],
					{
						opacity: [ 0, 1 ],
					},
				)
			},
		},
	],
})

for (const link of document.querySelectorAll('#wpadminbar a'))
	link.setAttribute('data-transition-prevent', 'self')
