import Alpine from 'alpinejs'
import { animate } from 'motion'

Alpine.data(
	'navbar',
	() => ({

		/* binding objects */

		menu: {
			['x-effect']() {
				const { isOpen } = Alpine.store('navigation')

				if (isOpen) {
					this.$el.style.setProperty('transform', 'translateY(0)')
				} else {
					this.$el.style.removeProperty('transform')
				}
			},
		},

		toggle: {
			['x-effect']() {
				const { isOpen } = Alpine.store('navigation')

				const BARS = this.$el.querySelectorAll('rect')
				const [ BAR_FIRST, BAR_SECOND ] = BARS

				const ANIMATION_DURATION = 250 /* time in ms */

				if (isOpen) {

					for (const BAR of BARS) {
						BAR.style.setProperty('y', '45px')
						BAR.style.setProperty('transition', `
							rotate ${ ANIMATION_DURATION / 2 }ms ease-in-out ${ ANIMATION_DURATION / 2 }ms,
							y ${ ANIMATION_DURATION / 2 }ms ease-in-out
						`)
					}

					BAR_FIRST.style.setProperty('rotate', '45deg')
					BAR_SECOND.style.setProperty('rotate', '-45deg')

				} else {

					for (const BAR of BARS) {
						BAR.style.removeProperty('rotate')
						BAR.style.removeProperty('y')
						BAR.style.setProperty('transition', `
							rotate ${ ANIMATION_DURATION / 2 }ms ease-in-out,
							y ${ ANIMATION_DURATION / 2 }ms ease-in-out ${ ANIMATION_DURATION / 2 }ms
						`)
					}
				}
			},

			/* events */

			['@click']() {
				Alpine.store('navigation').toggle()
			},
		},

		posIndicator: {
			['x-data']() {
				return {
					initialized: false,
					hidden: true,

					update() {
						if (!this.initialized)
							return

						const { activeItem } = Alpine.store('navigation')

						if (!activeItem) {
							animate(
								this.$el,
								{
									scale: 0,
								},
							)

							this.hidden = true

							return
						}

						const { left, width } = activeItem.getBoundingClientRect()
						const position = {
							left: `${ left + width / 2 }px`,
							bottom: '12px',
						}

						if (this.hidden) {
							this.$el.style.setProperty('left', position.left)
							this.$el.style.setProperty('bottom', position.bottom)

							animate(
								this.$el,
								{
									scale: 1,
								},
							)

							this.hidden = false
						} else {
							animate(
								this.$el,
								{
									scale: 1,
									left: position.left,
									bottom: position.bottom,
								},
							)
						}
					},
				}
			},

			['x-effect']() {
				this.update()
			},

			['@resize.window']() {
				this.update()
			},

			['@load.window']() {
				this.initialized = true
			},
		},
	}),
)
